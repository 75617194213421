<template>
  <section class="image">
    <img :src="src" :alt="title" class="img">
    <h1 class="title" v-html="title"></h1>
  </section>
</template>

<script>
export default {
  name: 'HeaderImage',
  props: {
    src: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.image {
  margin-bottom: 15px;
}
.title {
  position: absolute;
  top: 25px;
  right: 0;
  text-align: right;
  display: inline-block;
  max-width: 80%;
  letter-spacing: 2px;
  line-height: 1;
  text-shadow: 1px 1px 3px rgba(255,255,255,0.2);
  background: rgba(255, 255, 255, 0.2);
  padding: 8px 20px 10px 10px;
}
.img {
  width: 100%;
  display: block;
  object-fit: cover;
}
</style>
